import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import { Link } from "gatsby"
import { Container } from "../components/global"
import styled from "styled-components"

const NotFoundPage = () => (
  <Layout>
    <Container>
    <SEO title="404: Not found" />
      <Link to="/">
        <Button>Back to homepage</Button>
      </Link>
      <h1>Oops!</h1>
    <p>You didn't brake internet, but we can't find what you are looking for.</p>
    </Container>
  </Layout>
)


const Button = styled.button`

  color: ${(props) => props.theme.color.primary};
  padding: 1em;
  margin-left: 8px;
  border-radius: 5px;
  text-decoration: none;
  background-color: #ffd502;
  border: 3px solid black;
  font-weight: bolder;
  margin-left: 2.5em;
  margin-top: 3em;

  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    margin-left: 0;
  }
`

export default NotFoundPage
